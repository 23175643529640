

































































import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import SearchBar from '@/components/SearchBar.vue';
import AddButton from '@/components/AddButton.vue';
import BaseTable from '@/components/BaseTable.vue';
import Card from '@/components/Cards/Card.vue';
import { RealtimeExhibitionDTO } from '@/data/dto/realtime-exhibition.dto';
import { RealtimeMarket } from '@/data/dto/realtime-market.dto';
import 'moment-timezone';

@Component({
  components: {
    BaseTable,
    SearchBar,
    AddButton,
    Card,
  },
})
export default class RealtimeSchedule extends Vue {
  isLoading = true

  isOnError = false

  search = ''

  marketList: string[] = []

  exhibitions: RealtimeExhibitionDTO[] = []

  marketId = null

  get currentDay() {
    const currentDay = moment().tz('America/Sao_Paulo');
    const beforeTime = moment.tz('00:00:00', 'HH:mm:ss', 'America/Sao_Paulo');
    const afterTime = moment.tz('04:00:00', 'HH:mm:ss', 'America/Sao_Paulo');

    if (currentDay.isBetween(beforeTime, afterTime)) {
      currentDay.subtract(1, 'day');
    }

    return currentDay.format('YYYY-MM-DD');
  }

  columns = [
    'Ordem',
    'Programa',
    'Inicio ',
    'Fim ',
  ]

  async getRealtimeMarketsConfig() {
    try {
      this.isLoading = true;
      const realtimeMarketConfig = await this.$store.dispatch('getRealtimeMarketsConfig');
      this.marketList = [...new Set(realtimeMarketConfig
        .map((market: RealtimeMarket) => market.name))] as string[];
    } catch (error) {
      this.$notification.notify({
        message: 'Não foi possível completar a operação. Por favor verifique a sua conexão ou tente novamente.',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
      this.isOnError = true;
    } finally {
      this.isLoading = false;
    }
  }

  async mounted() {
    await this.getRealtimeMarketsConfig();
  }

  formatDateToTimeHour(date: string) {
    return moment.tz(date, 'America/Sao_Paulo').format('HH:mm:ss');
  }

  async getExhibitions() {
    try {
      this.isLoading = true;
      this.exhibitions = await this.$store.dispatch('getRealtimeSchedule', { marketName: this.marketId, date: this.currentDay });
    } catch (error) {
      this.$notification.notify({
        message: 'Não foi possível completar a operação. Por favor verifique a sua conexão ou tente novamente.',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
      this.isOnError = true;
    } finally {
      this.isLoading = false;
    }
  }

  updateSearch(search = '') {
    this.search = search;
  }

  scheduleFilteredBySearch() {
    return this.$searchUtils.searchAll<RealtimeExhibitionDTO>(this.search, this.exhibitions,
      (exhibition) => exhibition.programName);
  }
}
